import { Text } from 'components/Text'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

import { Block } from '../Block'
import * as marketingBlocksStyles from './marketingBlocksStyles'
import { AnimatedBlock } from '../AnimatedBlock'
import { Body } from '@sc/components/Typography'

const MarketingBlocks = () => {
  const { t } = useTranslationPrefix('marketing.content')

  return (
    // Safari: needed with container
    <div className={marketingBlocksStyles.container()}>
      <Block
        variant="fullWidth"
        css={{
          backgroundColor: '$surfaceBg'
        }}
      >
        <Body variant="large">{t('title1')}</Body>
      </Block>

      <div className={marketingBlocksStyles.content()}>
        <div className={marketingBlocksStyles.contentGroup()}>
          <Block
            variant="title"
            css={{
              backgroundColor: '$button'
            }}
          >
            <Body variant="large">{t('sectionTitle1')}</Body>
          </Block>

          <Block variant="description">
            <Body variant="small">{t('sectionDescription1')}</Body>
          </Block>
        </div>

        <AnimatedBlock
          animationPath="animation/graphs-benefit"
          className={marketingBlocksStyles.graphsBenefit()}
        />
      </div>

      <div className={marketingBlocksStyles.content()}>
        <div className={marketingBlocksStyles.contentGroup()}>
          <Block
            variant="title"
            css={{
              backgroundColor: '$button'
            }}
          >
            <Body variant="large">{t('sectionTitle2')}</Body>
          </Block>

          <Block variant="description">
            <Body variant="small">{t('sectionDescription2')}</Body>
          </Block>
        </div>

        <AnimatedBlock
          animationPath="animation/entry-benefit"
          className={marketingBlocksStyles.entryBenefit()}
        />
      </div>

      <div className={marketingBlocksStyles.content()}>
        <div className={marketingBlocksStyles.contentGroup()}>
          <Block
            variant="title"
            css={{
              backgroundColor: '$button'
            }}
          >
            <Body variant="large">{t('sectionTitle3')}</Body>
          </Block>

          <Block variant="description">
            <Body variant="small">{t('sectionDescription3')}</Body>
          </Block>
        </div>

        <AnimatedBlock
          animationPath="animation/tag-benefit"
          className={marketingBlocksStyles.tagBenefit()}
        />
      </div>

      <Block
        css={{
          backgroundColor: '$surfaceBg',
          ...marketingBlocksStyles.mainTitle
        }}
        variant="fullWidth"
      >
        <Body variant="large">{t('title2')}</Body>
      </Block>

      <div className={marketingBlocksStyles.contentGroup()}>
        <Block
          variant="titleAndDescription"
          title="$0"
          description={t('priceDescription1')}
        />

        <Block
          variant="titleAndDescription"
          title="$12"
          description={t('priceDescription2')}
        />
      </div>
    </div>
  )
}

export { MarketingBlocks }
