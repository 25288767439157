import React, { useReducer, useCallback } from 'react'
import { useSpring, animated } from 'react-spring'

import * as marketingHeroStyles from './marketingHeroStyles'
import { marketingHeroConfig } from './marketingHeroConfig'

const MarketingHero = () => {
  const [titleIndex, changeIndex] = useReducer(
    index => (index === 1 ? 0 : 1),
    0
  )

  const textAnimation = useCallback(async next => {
    const changeTitle = async () => {
      await next({ fontWeight: 1 })
      changeIndex()
    }

    await next({ fontWeight: 80 })
    await changeTitle()
  }, [])

  const styles = useSpring({
    // There's a fontWeight value between 0 and 1
    // that has a bug where the displayed fontWeight
    // is greater than the actual value, so it clips
    // on the browser
    from: { fontWeight: 1 },
    to: textAnimation,
    config: {
      duration: marketingHeroConfig.duration,
      easing: marketingHeroConfig.bezier
    },
    loop: true
  })

  return (
    <div className={marketingHeroStyles.container()}>
      <animated.p
        className={marketingHeroStyles.text()}
        style={{
          fontVariationSettings: styles.fontWeight.to(fontWeight => {
            return `"wght" ${fontWeight}`
          })
        }}
      >
        {marketingHeroConfig.texts[titleIndex].map((text: string) => (
          <span className={`index-${titleIndex.toString()}`} key={text}>
            {text}
          </span>
        ))}
      </animated.p>
    </div>
  )
}

export { MarketingHero }
