import { Text } from 'components/Text'
import { ThemeSwitcher } from 'components/ThemeSwitcher'

import * as marketingTopBarStyles from './marketingTopBarStyles'

const MarketingTopBar = () => {
  return (
    <div className={marketingTopBarStyles.container()}>
      <Text as="h1" size="large">
        Odo
      </Text>
      <ThemeSwitcher
        css={{
          backgroundColor: 'transparent',
          '& > svg': {
            height: '19px',
            width: '19px',
            '@sm': {
              height: '28px',
              width: '28px'
            }
          }
        }}
      />
    </div>
  )
}

export { MarketingTopBar }
