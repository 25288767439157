import { Marketing } from 'modules/Marketing'
import { NextPage } from 'next'
import Head from 'next/head'

const MarketingPage: NextPage = () => {
  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/fonts/TINY5x3GX.ttf"
          as="font"
          type="font/ttf"
          crossOrigin=""
        />
        <link rel="canonical" href="https://odo.p22.studio" />
        <meta property="og:title" content="Odo - Your Team Odometer" />
        <meta
          property="description"
          content="Track time, report, gain insights, and improve your teams productivity and performance."
        />
        <meta
          property="og:description"
          content="Track time, report, gain insights, and improve your teams productivity and performance."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://odo.p22.studio" />
        <meta property="og:image" content="/social-banner.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>

      <Marketing />
    </>
  )
}

export default MarketingPage
