import { css } from 'theme/stitches.config'

export const container = {
  alignItems: 'center',
  display: 'flex',
  gridColumn: '1 / -1',
  justifyContent: 'space-between',
  zIndex: 1,
  position: 'relative' as any
}

export const content = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  flex: 1
})

export const flexUtil = css({
  flex: 1,
  display: 'none',
  '@sm': {
    display: 'block'
  }
})

export const aProductBy = css({
  flex: 1,
  display: 'none',
  '@sm': {
    display: 'inline-flex'
  }
})

export const button = css({
  backgroundColor: 'transparent',
  fontSize: '$small',
  color: '$text',
  '&:hover': {
    opacity: '0.5'
  }
})
