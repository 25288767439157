import { styled } from 'theme/stitches.config'

/** @deprecated: use Body from scaffold */
export const Text = styled('span', {
  variants: {
    color: {
      base: {
        color: '$text'
      },
      medium: {
        color: '$textLowContrast'
      },
      error: {
        color: '$textDanger'
      }
    },
    size: {
      small: {
        fontSize: '$small',
        lineHeight: '$tight',
        letterSpacing: '$tight'
      },
      base: {
        fontSize: '$base',
        lineHeight: '$normal',
        letterSpacing: '$base'
      },
      large: {
        fontSize: '$mlarge',
        lineHeight: '$relaxed',
        '@sm': {
          fontSize: '$large',
          lineHeight: '$loose'
        },
        letterSpacing: '$base'
      }
    }
  },

  defaultVariants: {
    color: 'base',
    size: 'base'
  }
})
