import { css } from 'theme/stitches.config'

export const container = css({
  display: 'grid',
  gap: '$controlPadding',
  marginTop: '$controlPadding'
})

export const content = css({
  display: 'flex',
  flexWrap: 'wrap',
  gridGap: '$controlPadding',
  position: 'relative'
})

export const contentGroup = css({
  flex: 1,
  display: 'grid',
  gridGap: '$2',
  gridTemplateColumns:
    'repeat(auto-fit, minmax(min(max(40vw, 330px), 100%), 1fr))',
  minWidth: 'min(330px, 100%)',
  position: 'relative'
})

export const description = {
  minHeight: '339px'
}

export const mainTitle = {
  minHeight: '$$smallHeight',
  '@sm': {
    minHeight: '$$mediumHeight'
  }
}

export const text = {
  lineHeight: '2.2rem',
  fontSize: '1.9rem',
  fontWeight: '$semibold',
  '@sm': {
    lineHeight: '$loose',
    fontSize: '$large'
  }
}

const baseBenefit = {
  padding: 0,
  flex: 2,
  height: 'auto',
  minWidth: 'min(815px, 100%)',
  '.text-white': {
    filter: 'none'
  },
  '.text-white path': {
    fill: '$text'
  },
  '.stroke-outline': {
    stroke: '$outline'
  },
  '.fill-white': {
    fill: '$text'
  },
  '.fill-white path': {
    fill: '$text'
  },
  '.stroke-text-color': {
    stroke: '$text'
  },
  '.fill-button path': {
    fill: '$text'
  },
  '.text-color path': {
    fill: '$text'
  },
  '.text-color-invert path': {
    fill: '$textInverted'
  },
  '.text-tag-light': {
    fill: '$white'
  },
  '.text-tag-dark': {
    fill: '$black'
  },
  '.fill-panel': {
    fill: '$panel'
  },
  '.fill-button': {
    fill: '$button'
  },
  '.fill-background': {
    fill: '$panel'
  },
  '.stroke-background': {
    stroke: '$panel'
  },
  '.stroke-text-color-invert': {
    stroke: '$textInverted'
  },
  '.stroke-divider': {
    stroke: '$divider'
  },
  '.fill-text-color': {
    fill: '$text'
  }
}

export const graphsBenefit = css({
  ...baseBenefit,
  // YELLOWY GREEN
  '.tag-colour-11': {
    fill: '#888D2A'
  },
  '.stroke-tag-colour-11': {
    stroke: '#888D2A'
  },
  // GREEN
  '.tag-colour-12': {
    fill: '#3D723B'
  },
  '.stroke-tag-colour-12': {
    stroke: '#3D723B'
  },
  // ORANGE
  '.stroke-tag-colour-14': {
    stroke: '#FF7340'
  },
  '.stroke-text-colour': {
    stroke: '$text'
  },
  '.text-colour path': {
    fill: '$text'
  },
  'path.tag-colour-14': {
    fill: '#FF7340'
  }
})

export const entryBenefit = css({
  ...baseBenefit,
  '.text-color': {
    filter: 'none'
  },
  '.text-color-invert': {
    filter: 'none'
  }
})

export const tagBenefit = css({
  ...baseBenefit,
  '.text-color': {
    color: '$text'
  }
})
