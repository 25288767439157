import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@sc/components/Button'
import { Block } from '../Block'
import { AuthModals, AuthModalsRoutes } from 'modules/shared/AuthModals'
import { Text } from 'components/Text'
import { Alerts } from 'components/Alerts'
import * as actions from 'services/actions/authActions'

import * as marketingAuthStyles from './marketingAuthStyles'
import { Body } from '@sc/components/Typography'

export const MarketingAuth = () => {
  const [authModal, setAuthModal] = useState<AuthModalsRoutes>(null)
  const router = useRouter()

  async function redirectAfterSignIn(email: string, password: string) {
    const { redirectTo } = await actions.signIn(email, password)
    await router.push('/' + redirectTo)
  }

  async function redirectAfterSignUp(
    email: string,
    password: string,
    name: string
  ) {
    const { redirectTo } = await actions.signUpAndSetupWorkspace(
      email,
      password,
      name
    )
    await router.push('/' + redirectTo)
  }

  return (
    <Block css={{ height: 45 }} style={marketingAuthStyles.container}>
      <Body css={{ flex: 1 }}>
        <Body className={marketingAuthStyles.aProductBy()}>
          a product&nbsp;
        </Body>
        by p22.studio
      </Body>

      <div className={marketingAuthStyles.content()}>
        <Button
          className={marketingAuthStyles.button()}
          css={{ marginRight: '$1' }}
          onClick={() => setAuthModal('signup')}
        >
          Sign up
        </Button>

        <Button
          className={marketingAuthStyles.button()}
          onClick={() => setAuthModal('signin')}
        >
          Sign in
        </Button>
      </div>
      <div className={marketingAuthStyles.flexUtil()} />

      <AuthModals
        currentRoute={authModal}
        setRoute={setAuthModal}
        onSignUp={redirectAfterSignUp}
        onSignIn={redirectAfterSignIn}
        onForgotPassword={actions.sendForgotPasswordEmail}
      />

      <Alerts />
    </Block>
  )
}
