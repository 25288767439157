import { useEffect, useState, useRef } from 'react'
import useMedia from 'hooks/useMedia'
import lottie, { AnimationItem } from 'lottie-web'
import { useSpring } from 'react-spring'

/**
 * Deffers loading lottie animation until the component is scrolled near the
 * viewport to speed up initial page load
 * @param {string} path - path to lottie animation
 * @returns ref for annimaanimationtion parent component
 */
const useLottieDeferred = (path: string) => {
  const ref = useRef(null)
  const animation = useRef<AnimationItem>()

  // For a11y label
  const [percentage, setPercentage] = useState(0)
  const [{ progress }, { set: setProgress }] = useSpring(() => ({
    progress: 0,
    onChange: ({ value }) => {
      setPercentage(Math.round(value.progress * 100))
    }
  }))

  const { sm, md } = useMedia()

  const animationPath = sm
    ? `${path}-mobile.json`
    : md
    ? `${path}-tablet.json`
    : `${path}-desktop.json`

  useEffect(() => {
    const observer = new IntersectionObserver(
      e => {
        if (e[0].isIntersecting) {
          animation.current = lottie.loadAnimation({
            container: ref.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: animationPath
          })

          animation.current.addEventListener(
            'enterFrame',
            ({ currentTime, totalTime }) => {
              setProgress({ progress: currentTime / totalTime })
            }
          )

          // No need to watch anymore
          observer.disconnect()
        }
      },
      { threshold: 0.5, rootMargin: '30px' }
    )
    observer.observe(ref.current)
    return () => {
      animation.current?.destroy()
      observer.disconnect()
    }
  }, [animationPath, setProgress])

  const handleProgress = (selectedProgress: number) => {
    animation.current?.goToAndPlay(
      selectedProgress * animation.current.totalFrames,
      true
    )
  }

  return { ref, progress, setProgress: handleProgress, percentage }
}

export { useLottieDeferred }
