import { css } from 'theme/stitches.config'

export const container = css({
  alignItems: 'center',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$5',
  position: 'fixed',
  width: '100%',
  zIndex: '$navigation'
})
