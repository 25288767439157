import { MarketingAuth } from './MarketingAuth'
import { MarketingBlocks } from './MarketingBlocks'
import { MarketingHero } from './MarketingHero'
import * as marketingStyles from './marketingStyles'
import { MarketingTopBar } from './MarketingTopBar'

export const Marketing = () => {
  return (
    <main>
      <MarketingTopBar />
      <div className={marketingStyles.container()}>
        <MarketingHero />

        <div className={marketingStyles.blocksContainer()}>
          <MarketingAuth />

          <MarketingBlocks />
        </div>

        <div className={marketingStyles.footer()} />

        <MarketingAuth />
      </div>
    </main>
  )
}
