import { ProgressBarAnimated } from '@sc/components/ProgressBar'

import { useLottieDeferred } from './useLottieDeferred'
import { Block, BlockProps } from '../Block'

type AnimatedBlockProps = BlockProps & {
  animationPath: string
  scrubber?: boolean
}

const AnimatedBlock = ({
  animationPath,
  scrubber = true,
  ...props
}: AnimatedBlockProps) => {
  const { ref, progress, setProgress, percentage } =
    useLottieDeferred(animationPath)

  return (
    <Block {...props} css={{ position: 'relative' }} ref={ref}>
      {scrubber && (
        <ProgressBarAnimated
          percentage={percentage}
          progress={progress}
          onSetProgress={setProgress}
          css={{
            position: 'absolute',
            cursor: 'pointer',
            bottom: '1.2rem',
            left: '1.6rem',
            width: 'calc(100% - 3.2rem)',
            backgroundColor: '$button',
            '&:hover': {
              height: '1rem'
            },
            transition: 'height 0.1s, background-color 0.3s'
          }}
        />
      )}
    </Block>
  )
}

export { AnimatedBlock }
