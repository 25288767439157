import React, { RefObject, useRef } from 'react'
import { animated, SpringValue, to } from 'react-spring'
import { useDrag } from '@use-gesture/react'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { CSS, styled } from '@sc/theme/stitches.config'

const StyledRoot = styled(ProgressPrimitive.Root, {
  backgroundColor: '$strokePrimary',
  height: '0.5rem',
  borderRadius: '$M',
  zIndex: 1,
  overflow: 'hidden',
  width: '100%'
})

const StyledIndicator = styled(ProgressPrimitive.Indicator, {
  backgroundColor: '$fgHigh',
  height: '100%',
  width: '100%',
  transition: 'transform 10ms cubic-bezier(0.65, 0, 0.35, 1)'
})

type ProgressSetter = (progressSelected: number) => void

type ProgressRef = RefObject<HTMLDivElement>

const useClickProgress = (onSetProgress: ProgressSetter, ref: ProgressRef) => {
  const handleClick = (e: React.MouseEvent) => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const offset = rect.left
      const width = rect.width
      const position = e.pageX - offset
      const selectedProgress = position / width

      if (offset < e.pageX) {
        onSetProgress(selectedProgress)
      }
    }
  }

  return handleClick
}

const useDragProgress = (onSetProgress: ProgressSetter, ref: ProgressRef) => {
  const drag = useDrag(
    ({ active, xy: [x] }) => {
      if (ref.current && active) {
        const rect = ref.current.getBoundingClientRect()
        const offset = rect.left
        const width = rect.width
        const position = x - offset
        const selectedProgress = position / width

        if (selectedProgress >= 0 && selectedProgress <= 1) {
          onSetProgress(selectedProgress)
        }
      }
    },
    {
      axis: 'x',
      preventScroll: true
    }
  )

  return drag
}

type ProgressBarProps = ProgressPrimitive.ProgressProps & {
  progress: number
  css?: CSS
  onSetProgress?: ProgressSetter
}

/**
 * ProgressBar component.
 * This is not animated.
 */
export const ProgressBar = ({
  progress,
  css,
  onSetProgress = () => {}
}: ProgressBarProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const handleClick = useClickProgress(onSetProgress, ref)

  return (
    <StyledRoot onClick={handleClick} css={css} value={progress} ref={ref}>
      <StyledIndicator css={{ transform: `translateX(-${100 - progress}%)` }} />
    </StyledRoot>
  )
}

const StyledIndicatorAnimated = animated(StyledIndicator)

type ProgressBarAnimatedProps = Omit<ProgressBarProps, 'progress'> & {
  /** Controls the position of the progress bar without React rerenders */
  progress: SpringValue<number>
  /** Used for a11y progress values. Causes rerenders so should not be updated frequently */
  percentage: number
}

/**
 * Animated `ProgressBar` component
 */
export const ProgressBarAnimated = ({
  onSetProgress = () => {},
  progress,
  percentage,
  css,
  onMouseUp
}: ProgressBarAnimatedProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const handleClick = useClickProgress(onSetProgress, ref)
  const drag = useDragProgress(onSetProgress, ref)
  const transform = to([progress], (v) => `translateX(-${100 - v * 100}%)`)

  return (
    <StyledRoot
      aria-label="Animated progress bar"
      onClick={handleClick}
      onMouseUp={onMouseUp}
      css={css}
      value={percentage}
      ref={ref}
    >
      <StyledIndicatorAnimated style={{ transform }} {...drag()} />
    </StyledRoot>
  )
}
