import React, { forwardRef } from 'react'
import { styled } from 'theme/stitches.config'
import { Body } from '@sc/components/Typography'

const StyledBlock = styled('div', {
  height: '100%',
  $$smallHeight: '173px',
  $$mediumHeight: '339px',
  backgroundColor: '$surfaceBg',
  borderRadius: '$panel',
  display: 'flex',
  padding: '$3',
  variants: {
    variant: {
      title: {
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '$base',
        justifyContent: 'center',
        minHeight: '$$smallHeight',
        [`& ${Body}`]: {
          width: 'clamp(272px, 75%, 290px)'
        },
        '@sm': {
          minHeight: '$$mediumHeight'
        }
      },
      description: {
        fontSize: '$small',
        minHeight: '$$smallHeight',
        [`& ${Body}`]: {
          width: '71%'
        },
        '@sm': {
          minHeight: '$$mediumHeight'
        }
      },
      titleAndDescription: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '$$smallHeight',
        padding: '$3',
        '& > *:nth-child(2)': {
          alignSelf: 'center',
          textAlign: 'center',
          fontSize: '59px',
          color: '$text',
          fontFeatureSettings: "'pnum'"
        },
        '& > *:nth-child(3)': {
          color: '$text',
          marginTop: '$3'
        },
        '@sm': {
          minHeight: '$$mediumHeight'
        }
      },
      fullWidth: {
        alignItems: 'center',
        flex: 1,
        textAlign: 'center',
        fontSize: '$base',
        minHeight: '$$mediumHeight',
        justifyContent: 'center',
        [`& ${Body}`]: {
          width: 'min(100%, 775px)'
        }
      }
    }
  }
})

export type BlockProps = React.ComponentProps<typeof StyledBlock> & {
  description?: string
  title?: string
  width?: number | string
}

const Block = forwardRef<HTMLDivElement, BlockProps>(function Block(
  { children, description, title, variant, ...props },
  ref
) {
  return (
    <StyledBlock variant={variant} {...props} ref={ref}>
      {variant === 'titleAndDescription' && (
        <>
          <div />
          <Body>{title}</Body>
          <Body variant="small">{description}</Body>
        </>
      )}
      {children}
    </StyledBlock>
  )
})

export { Block }
