import { css } from 'theme/stitches.config'

export const container = css({
  height: 'calc(100vh - 65px)',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative'
})

export const text = css({
  display: 'flex',
  margin: 0,
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 65px)',
  textAlign: 'center' as any, // https://github.com/pmndrs/react-spring/issues/1645
  color: '$text',
  fontFamily: 'Tiny Variable',
  fontSize: 'clamp(100px, 17vw, 272px)',
  lineHeight: 'initial',
  position: 'fixed' as any,
  width: 'calc(100vw - 20px)',
  zIndex: 0,
  flexDirection: 'column',
  '& span.index-0:nth-child(1), & span.index-0:nth-child(2)': {
    display: 'flex'
  }
})
