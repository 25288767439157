import MarketingHome from 'assets/img/marketing_home.svg'
import { IconButton } from '@sc/components/Button'
import { useTheme } from 'next-themes'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

type ThemeSwitcherProps = Pick<React.ComponentProps<typeof IconButton>, 'css'>

export const ThemeSwitcher = ({ css = {} }: ThemeSwitcherProps) => {
  const { setTheme, resolvedTheme } = useTheme()
  const { t } = useTranslationPrefix('marketing')

  const toggleTheme = () => {
    const targetTheme = resolvedTheme === 'light' ? 'dark' : 'light'
    setTheme(targetTheme)
  }

  return (
    <IconButton
      aria-label={
        resolvedTheme === 'light'
          ? t('themeSwitchDarkMode')
          : t('themeSwitchLightMode')
      }
      variant="secondary"
      onClick={toggleTheme}
      css={{
        '& > svg': {
          fill: '$text',
          height: '20px',
          width: '20px'
        },
        ...css
      }}
    >
      <MarketingHome />
    </IconButton>
  )
}
