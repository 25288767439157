import { css } from 'theme/stitches.config'

export const container = css({
  backgroundColor: '$surfaceFg',
  padding: '$2'
})

export const blocksContainer = css({
  background: '$gradientPanel',
  position: 'relative'
})

export const footer = css({
  height: 'calc(100vh - 65px)',
  position: 'relative',
  marginTop: '$2'
})
